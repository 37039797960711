var JS_TRIGGER_SELECTOR = '.js-clipboard-copy';
var DATA_ATTR_TEXT_KEY = 'clipboardText';
var DATA_ATTR_POPUP_KEY = 'popup';
var CSS_SHOWN_CLASS = 'tooltip-callout--is-shown';
function copyToClipboard(str) {
    var el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}
export function clipboardCopyInit() {
    var triggers = Array.from(document.querySelectorAll(JS_TRIGGER_SELECTOR));
    triggers.forEach(function (trigger) {
        var _a, _b;
        var popupElId = (_b = (_a = trigger.dataset) === null || _a === void 0 ? void 0 : _a[DATA_ATTR_POPUP_KEY]) !== null && _b !== void 0 ? _b : null;
        var popupEl = popupElId ? document.getElementById(popupElId) : null;
        var hideTimeoutId = null;
        trigger.addEventListener('click', function (evt) {
            var _a, _b, _c;
            var text = (_c = (_b = (_a = evt.currentTarget) === null || _a === void 0 ? void 0 : _a.dataset) === null || _b === void 0 ? void 0 : _b[DATA_ATTR_TEXT_KEY]) !== null && _c !== void 0 ? _c : null;
            if (text && evt.currentTarget) {
                copyToClipboard(text);
                if (popupEl) {
                    popupEl.classList.add(CSS_SHOWN_CLASS);
                    if (hideTimeoutId) {
                        clearTimeout(hideTimeoutId);
                    }
                    hideTimeoutId = setTimeout(function () {
                        popupEl.classList.remove(CSS_SHOWN_CLASS);
                    }, 3000);
                }
            }
            evt.preventDefault();
            return false;
        });
    });
}
