var JS_INIT_SELECTOR = '[data-button-protection]';
export function buttonProtectionInit() {
    var els = Array.from(document.querySelectorAll(JS_INIT_SELECTOR));
    els.forEach(function (el) {
        var hasBeenClicked = false;
        el.addEventListener('click', function (evt) {
            if (hasBeenClicked) {
                evt.preventDefault();
                return;
            }
            if (el.classList.contains('button')) {
                el.classList.add('button--disabled');
            }
            hasBeenClicked = true;
        });
    });
}
