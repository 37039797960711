//
// chart.js
// Theme module
//

import {
    ArcElement,
    BarController,
    BarElement,
    BubbleController,
    CategoryScale,
    Chart,
    Decimation,
    DoughnutController,
    Filler,
    Legend,
    LineController,
    LineElement,
    LinearScale,
    LogarithmicScale,
    PieController,
    PointElement,
    PolarAreaController,
    RadarController,
    RadialLinearScale,
    ScatterController,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip,
} from 'chart.js';

import {getCSSVariableValue} from './helpers';

Chart.register(
    ArcElement,
    BarController,
    BarElement,
    BubbleController,
    CategoryScale,
    Decimation,
    DoughnutController,
    Filler,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    LogarithmicScale,
    PieController,
    PointElement,
    PolarAreaController,
    RadarController,
    RadialLinearScale,
    ScatterController,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip
);

const colors = {
    gray: {
        300: getCSSVariableValue('--bs-chart-gray-300'),
        600: getCSSVariableValue('--bs-chart-gray-600'),
        700: getCSSVariableValue('--bs-chart-gray-700'),
        800: getCSSVariableValue('--bs-chart-gray-800'),
        900: getCSSVariableValue('--bs-chart-gray-900'),
    },
    primary: {
        100: getCSSVariableValue('--bs-chart-primary-100'),
        300: getCSSVariableValue('--bs-chart-primary-300'),
        700: getCSSVariableValue('--bs-chart-primary-700'),
    },
    black: getCSSVariableValue('--bs-dark'),
    white: getCSSVariableValue('--bs-white'),
    transparent: 'transparent',
};

//
// Functions
//

export function initCharts() {
    Chart.defaults.responsive = true;
    Chart.defaults.maintainAspectRatio = false;

    // Default
    Chart.defaults.font = {
        family: "Ultimatum Heavy Italic",
        weight: '700',
        size: 14,
    };
    Chart.defaults.color = '#fff';

    // Layout
    Chart.defaults.layout.padding = 0;

    // Legend
    Chart.defaults.plugins.legend.display = false;

    // Point
    Chart.defaults.elements.point.radius = 0;
    Chart.defaults.elements.point.backgroundColor = colors.primary[700];

    // Line
    Chart.defaults.elements.line.tension = 0.4;
    Chart.defaults.elements.line.borderWidth = 3;
    Chart.defaults.elements.line.borderColor = colors.primary[700];
    Chart.defaults.elements.line.backgroundColor = colors.transparent;
    Chart.defaults.elements.line.borderCapStyle = 'rounded';

    // Bar
    Chart.defaults.elements.bar.backgroundColor = '#61a1fc';
    Chart.defaults.datasets.bar.maxBarThickness = 30;

    // Arc
    // Chart.defaults.elements.arc.backgroundColor = colors.primary[700];
    Chart.defaults.elements.arc.backgroundColor = ['#2C7BE5', '#A6C5F7', '#D2DDEC', '#11b857', '#29c7a7', '#1ea768', '#32da8d', '#39afd1', '#23819c', '#70c3db'];
    Chart.defaults.elements.arc.borderColor = getCSSVariableValue('--bs-chart-arc-border-color');
    Chart.defaults.elements.arc.borderWidth = 4;
    Chart.defaults.elements.arc.hoverBorderColor = getCSSVariableValue('--bs-chart-arc-hover-border-color');

    // Tooltips
    Chart.defaults.plugins.tooltip.mode = 'index';

    // yAxis
    Chart.defaults.scales.linear.grid = {
        drawBorder: false,
        drawTicks: false,
    };

    Chart.defaults.scales.linear.ticks.beginAtZero = true;
    Chart.defaults.scales.linear.ticks.padding = 10;
    Chart.defaults.scales.linear.ticks.stepSize = 10;

    // xAxis
    Chart.defaults.scales.category.grid = {
        drawBorder: false,
        drawOnChartArea: false,
        drawTicks: false,
    };

    Chart.defaults.scales.category.ticks.padding = 20;
}

initCharts();

// Make available globally
window.Chart = Chart;
