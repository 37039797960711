var CSS_BODY_OVERFLOW_CLASS = 'overflow-hidden';
var CSS_BODY_OPENED_CLASS = 'global-menu-menu--is-opened';
var CSS_HIDDEN_CLASS = 'd-none';
var JS_TRIGGER_SELECTOR = '.js-global-mega-menu__trigger';
var JS_CONTENT_SELECTOR = '.js-global-mega-menu__content';
var bodyEl = null;
var contentEls = [];
var isOpen = false;
function show() {
    contentEls.forEach(function (el) {
        el.classList.remove(CSS_HIDDEN_CLASS);
    });
    if (bodyEl) {
        bodyEl.classList.add(CSS_BODY_OVERFLOW_CLASS);
        bodyEl.classList.add(CSS_BODY_OPENED_CLASS);
    }
    isOpen = true;
}
function hide() {
    contentEls.forEach(function (el) {
        el.classList.add(CSS_HIDDEN_CLASS);
    });
    if (bodyEl) {
        bodyEl.classList.remove(CSS_BODY_OVERFLOW_CLASS);
        bodyEl.classList.remove(CSS_BODY_OPENED_CLASS);
    }
    isOpen = false;
}
function handleClick(evt) {
    evt.preventDefault();
    if (isOpen) {
        hide();
    }
    else {
        show();
    }
    return false;
}
export function globalMegaMenuInit() {
    bodyEl = document.querySelector('body');
    var triggers = Array.from(document.querySelectorAll(JS_TRIGGER_SELECTOR));
    triggers.forEach(function (trigger) {
        trigger.addEventListener('click', handleClick);
    });
    contentEls = Array.from(document.querySelectorAll(JS_CONTENT_SELECTOR));
}
