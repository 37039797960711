import { debounce } from "lodash";
export function initGlobalPageHeader() {
    var _a;
    function getElementFromAnchor(anchor) {
        var _a, _b;
        return document.getElementById((_b = (_a = anchor.getAttribute('href')) === null || _a === void 0 ? void 0 : _a.replace('#', '')) !== null && _b !== void 0 ? _b : '');
    }
    function isBetweenInclusive(num, min, max, buffer) {
        var _a;
        if (buffer === void 0) { buffer = 0; }
        if (min > max) {
            _a = [max, min], min = _a[0], max = _a[1];
        }
        return num >= (min - buffer) && num <= (max + buffer);
    }
    function isSectionLink(el) {
        return el.classList.contains('sub-nav__link');
    }
    function makeScrollLinkTargetTops() {
        return originalScrollLinks.map(function (el) {
            var _a, _b, _c;
            if (!isSectionLink(el)) {
                return null;
            }
            return Math.max(((_b = (_a = getElementFromAnchor(el)) === null || _a === void 0 ? void 0 : _a.offsetTop) !== null && _b !== void 0 ? _b : 0) - ((_c = fixedEl === null || fixedEl === void 0 ? void 0 : fixedEl.offsetHeight) !== null && _c !== void 0 ? _c : 0), 0);
        });
    }
    function updateScrollLinks(links, activeIdx) {
        links.forEach(function (el, elIdx) {
            if (activeIdx === elIdx) {
                el.classList.add('sub-nav__link--is-active');
            }
            else {
                el.classList.remove('sub-nav__link--is-active');
            }
        });
    }
    function updateActiveLink(activeIdx, scrollLinkGroups) {
        scrollLinkGroups.forEach(function (group) {
            group.forEach(function (el) { return el.classList.remove('sub-nav__link--is-active'); });
            if (group[activeIdx].classList.contains('sub-nav__link')) {
                group[activeIdx].classList.add('sub-nav__link--is-active');
            }
        });
    }
    function updateFixedGlobalHeader() {
        if (window.scrollY > globalPageHeaderBottom) {
            fixedEl.classList.add('global-page-header--is-visible');
        }
        else {
            fixedEl.classList.remove('global-page-header--is-visible');
        }
    }
    function scrollWindowToElement(destEl, topOffset) {
        if (topOffset === void 0) { topOffset = 0; }
        if (destEl) {
            window.scrollTo(0, Math.max(0, destEl.offsetTop - (topOffset)));
        }
        else {
            window.scrollTo(0, 0);
        }
        return false;
    }
    function initScrollLinks(scrollLinks, scrollLinkGroups) {
        scrollLinks.forEach(function (el, idx) {
            el.addEventListener('click', function (evt) {
                var _a;
                evt.preventDefault();
                var target = evt.currentTarget;
                window.location.hash = (_a = target.getAttribute('href')) !== null && _a !== void 0 ? _a : '';
                document.removeEventListener('scroll', handleScroll);
                // console.log('turning off document scroll');
                scrollWindowToElement(getElementFromAnchor(target), fixedEl.offsetHeight);
                updateActiveLink(idx, scrollLinkGroups);
                // console.log('setting el as active', el);
                document.addEventListener('scroll', handleScroll);
                // console.log('turning on document scroll');
            });
        });
    }
    function getLinkIdxByHash(hash, links, fallbackIdx) {
        var linkIdx = links.findIndex(function (el, idx) {
            if (!isSectionLink(el)) {
                return false;
            }
            return el.getAttribute('href') === hash;
        });
        return linkIdx > -1 ? linkIdx : fallbackIdx;
    }
    function handleScroll(evt) {
        var scrollPos = window.scrollY;
        //make sure its at most 50 pixels from current scroll position, generally fixing initial scroll from 0 to 2000px
        if (scrollPos > lastScrollPos) {
            // scrolling down
            lastScrollPos = Math.max(lastScrollPos, scrollPos - 50);
        }
        else {
            // scrolling up,
            lastScrollPos = Math.min(lastScrollPos, scrollPos + 50);
        }
        var transitionIdx = scrollLinkTargetTops.findIndex(function (top) {
            if (top === null) {
                return false;
            }
            return isBetweenInclusive(top, scrollPos, lastScrollPos, 20);
        });
        // console.log(scrollPos, transitionIdx, scrollLinkTargetTops);
        if (transitionIdx > -1) {
            updateScrollLinks(originalScrollLinks, transitionIdx);
            updateScrollLinks(fixedScrollLinks, transitionIdx);
        }
        updateFixedGlobalHeader();
        lastScrollPos = scrollPos;
    }
    function handleResize(evt) {
        // console.log('handling resize');
        scrollLinkTargetTops = makeScrollLinkTargetTops();
    }
    var globalPageHeaderEl = document.querySelector(".global-page-header");
    // if we aren't doing sticky thing, don't do anything to the page
    if (!globalPageHeaderEl || !globalPageHeaderEl.classList.contains("global-page-header--is-sticky")) {
        return;
    }
    var fixedEl = globalPageHeaderEl.cloneNode(true);
    var globalContentEl = document.querySelector('.global-content');
    var lastScrollPos = 0;
    var globalPageHeaderBottom = globalPageHeaderEl.offsetTop + globalPageHeaderEl.offsetHeight;
    var originalScrollLinks = Array.from(document.querySelectorAll('.js-scroll-link'));
    var fixedScrollLinks = Array.from(fixedEl.querySelectorAll('.js-scroll-link'));
    var scrollLinkTargetTops = makeScrollLinkTargetTops();
    var scrollLinkGroups = [
        originalScrollLinks,
        fixedScrollLinks,
    ];
    // prevent automatic scrolling on refresh
    if (history.scrollRestoration) {
        history.scrollRestoration = 'manual';
    }
    // create duplicate of the page header
    document.body.appendChild(fixedEl);
    fixedEl.classList.add('global-page-header--is-fixed');
    var fixedElPricesApp = fixedEl.querySelector('.js-overall-prices-detail');
    if (fixedElPricesApp) {
        (_a = fixedElPricesApp.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(fixedElPricesApp);
    }
    // initialize links in the two page headers
    initScrollLinks(originalScrollLinks, scrollLinkGroups);
    initScrollLinks(fixedScrollLinks, scrollLinkGroups);
    // check if there is an initial active menu link in the hash
    var initialActiveIdx = getLinkIdxByHash(location.hash, originalScrollLinks, 1);
    if (initialActiveIdx !== 1) {
        scrollWindowToElement(getElementFromAnchor(originalScrollLinks[initialActiveIdx]), fixedEl.offsetHeight);
        updateActiveLink(initialActiveIdx, scrollLinkGroups);
    }
    // set up scroll and resize handlers
    document.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', debounce(handleResize, 100));
    // set up dom watcher so that things will recalc when react finishes loading in content
    if (globalContentEl) {
        var observer = new MutationObserver(debounce(handleResize, 100));
        observer.observe(globalContentEl, { subtree: true, childList: true });
    }
    // Update the status of the global header
    updateFixedGlobalHeader();
}
