// Vendor
import 'bootstrap';
import './Charts';
import { Tooltip } from 'bootstrap';
import { DomUtils } from "./utils";
import { globalMegaMenuInit } from './GlobalMegaMenu';
import { clipboardCopyInit } from "./ClipboardCopy";
import { initGlobalPageHeader } from "./GlobalPageHeader";
import { buttonProtectionInit } from "./ButtonProtection";
// Site
import "./sortable";
DomUtils.onDomContentLoaded(function () {
    var _a;
    globalMegaMenuInit();
    clipboardCopyInit();
    initGlobalPageHeader();
    buttonProtectionInit();
    if ((_a = window.fcproInitQueue) === null || _a === void 0 ? void 0 : _a.length) {
        window.fcproInitQueue.forEach(function (fn) { return fn(); });
    }
    if (Tooltip) {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
        var htmlTooltipTriggerList = [].slice.call(document.querySelectorAll('[data-html-tooltip]'));
        var htmlTooltipList = htmlTooltipTriggerList.map(function (tooltipTriggerEl) {
            var _a, _b;
            return new Tooltip(tooltipTriggerEl, {
                html: true,
                title: (_b = (_a = tooltipTriggerEl.dataset) === null || _a === void 0 ? void 0 : _a.htmlTooltip) !== null && _b !== void 0 ? _b : '',
            });
        });
    }
});
